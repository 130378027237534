@import "~antd/dist/antd.css";

.App {
  text-align: center;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.ant-table-tbody > tr.highlighted-row td {
  background-color: darksalmon;
}
.ant-table-tbody > tr.highlighted-row.ant-table-row-hover td {
  background-color: darksalmon;
}
.ant-table-tbody > tr.highlighted-row.ant-table-row-selected td {
  background-color: darksalmon;
}

.editable-row .ant-form-explain {
  position: absolute;
  font-size: 12px;
  margin-top: -4px;
}

/* Icon clear in Input fields */
.anticon-close-circle {
  cursor: pointer;
  color: #ccc;
  transition: color 0.3s;
  font-size: 12px;
}
.anticon-close-circle:hover {
  color: #999;
}
.anticon-close-circle:active {
  color: #666;
}

/* Remove up/down with input type number */
/* .ant-input-number-handler-wrap */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Editable Cells */
.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
}

.ant-upload-list {
  width: 200px;
  /* border: 1px solid; */
}

ul.ant-menu-horizontal {
  line-height: 30px;
  padding: 0px;
}

